//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n'

export default {
  name: 'app-product-export-page',
  props: ['id'],

  async created() {
    await this.doFind(this.id);
  },

  data() {
    return {
      model: {
        iterationNumber: undefined, 
        barcode: undefined, 
      },
      saveLoading: false,
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.products') 
    },
  },

  computed: {
    ...mapGetters({
      record: 'product/view/record',
      loading: 'product/view/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    language(){
      return i18n.locale
    }
  },
  methods: {
    ...mapActions({
      doFind: 'product/view/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    doSubmit() {

    },
  },
};
